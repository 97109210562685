import React from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './infra.css'
import Container from 'react-bootstrap/esm/Container';
import {BsPatchCheckFill} from 'react-icons/bs'
import pic_1 from './images/2.png'
import pic_2 from './images/7.png'
import pic_3 from './images/4.png'
import pic_4 from './images/5.png'
import pic_5 from './images/3.png'

const presses=[
    {
      id:1,
      name:"Heidelberg 6 Plus Coater (28-40)"
    },
    {
        id:2,
        name:"Heidelberg 6 Plus Coater (20-28)"
      },
      {
        id:3,
        name:"Heidelberg 5 Plus Coater (20-28)"
      },
      {
        id:4,
        name:"Heidelberg SORMZ"
      },
]

const postpress =[
    {
        id:1,
        name:"Dyone Automatic Die Cutting (1 Nos.)",
    },
    {
        id:2,
        name:"Heidleberg Cylinder Die Cutting (4 Nos.)",
    },
    {
        id:3,
        name:"Mannual Die Cutting Machines (3 Nos.)",
    },
    {
        id:4,
        name:"Automatic Pasting Machine (4 Nos.)",
    },
    {
        id:6,
        name:"Automatic Screeen Printing (1 Nos.)",
    },
    {
        id:7,
        name:"Fully Automatic Cutting Machines (3 Nos.)",
    },


]

const corrugation =[
    {
        id:1,
        name:"E-Flute Fingreless Corrugation"
    },
    {
        id:2,
        name:"Automatic Flute Laminator"
    },
    {
        id:3,
        name:"Mannual Pastings"
    },
]

const Infra = () => {
  return (
    <Container id="infra-nav" className="infra-container">
        <h1 className='title-infra'>INFRASTRUCTURE</h1>
        <div className='text-div-infra'>
            <span>
            We work on one of the exclusive German and Chinese machines that are capable of world class printing and box manufacturing. Our three production lines can cater 6 lakh boxes per day or we can say we have the capability to deliver 100,000 sheets a day through this machinery.
            We are the only packaging house in tri-city equipped with the advanced in-house automation!

            </span>
        </div>
         <Row xs={1} md={2} className="g-4">
        <Col>
          <Card className="card-infra">
            <Card.Body>
              <Card.Title className="card-title-infra"> <BsPatchCheckFill/> PRESSES</Card.Title>
              <Card.Text>
              <ul>
                    {presses.map( (machines,idx) =>(
                       <li key={idx}>{machines.name}</li>
                    ))}   
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
        <Col>
          <Card className="card-infra" data-aos="zoom-out-left" data-aos-duration="1000"> 
            <Card.Img variant="top" src={pic_1}  />
          </Card>
        </Col>

        <Col>
          <Card className="card-infra" data-aos="zoom-out-right" data-aos-duration="1000"> 
            <Card.Img variant="top" src={pic_2}  />
          </Card>
        </Col>

        <Col>
          <Card className="card-infra">
            <Card.Body>
              <Card.Title className="card-title-infra"> <BsPatchCheckFill/> PREPRESS</Card.Title>
              <Card.Text>
              <span>
                Powered with in house CTP along with complete prepress and design house, Sushobhit has a workforce of most senior designers along 
                with the support of Corel and press managment Effi Print softwares.
               </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="card-infra">
            <Card.Body>
              <Card.Title className="card-title-infra"> <BsPatchCheckFill/> POSTPRESS</Card.Title>
              <Card.Text>
              <ul>
                    {postpress.map( (machines, idx) =>(
                       <li key={idx}>{machines.name}</li>
                    ))}   
                </ul>
              
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="card-infra"  data-aos="zoom-out-left" data-aos-duration="1000"> 
            <Card.Img variant="top" src={pic_3} />
          </Card>
        </Col>
      
        <Col>
          <Card className="card-infra" data-aos="zoom-out-right" data-aos-duration="1000"> 
            <Card.Img variant="top" src={pic_4} />
          </Card>
        </Col>

        <Col>
          <Card className="card-infra">
            <Card.Body>
              <Card.Title className="card-title-infra"> <BsPatchCheckFill/> CORRUGATION</Card.Title>
              <Card.Text>
              <ul>
                    {corrugation.map( (machines,idx) =>(
                       <li key={idx}>{machines.name}</li>
                    ))}   
                </ul>
              
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="card-infra">
            <Card.Body>
              <Card.Title className="card-title-infra"> <BsPatchCheckFill/> LAMINATION</Card.Title>
              <Card.Text>
              <span> Fully Automatic Lamination with the capacity of 50000 sheets &#10088; 40&#34; - 40&#34; &#10089; per day for metpet and bopp both kind of laminations.</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="card-infra" data-aos="zoom-out-left" data-aos-duration="1000"> 
            <Card.Img variant="top" src={pic_5} />
          </Card>
        </Col>

    </Row>
    </Container>
  )
}

export default Infra