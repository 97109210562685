import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from './Components/Navbar/Topbar';
import Home from './pages/Home';

function App() {
    AOS.init();
  return (
    <div className="App">
      <Topbar/>
      <Home/>

    </div>
  );
}

export default App;
