import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import { Box } from '@mui/system';
import './history.css'
import Video from '../Video/Video'

const data =[
    {
        id:1,
        title:"Career",
        desc:"The most important component of any workforce are the people. Sushobhit have a boosts of an excellent workforce at highly motivated  people trained in-house and adept at handling state-of-the-art equipment."
    },
    {
        id:2,
        title:" Company Roadmap ",
        desc:"Started in 1988, we have the anual turnover of 250 million anually with best of machinery and equipments in printing and packagig insdustry. We are working on the principle to deliver every job with minimum invetory and packging requirments. "
    },
    {
        id:3,
        title:" Resources",
        desc:"We currenty have 4 working units situated in 20 thousand square feet area who are potentially working on providing complete paper packaging solution to the consumers.    "
    }
]

function History() {
  return (
    <Box className="outer-box" >
  <Container className="container-history">
   
    
   
   <div className="title-div">
   <h1 className="title-box">WHO ARE WE ? </h1>
      <Video />
    </div>
  
<Row xs={1} sm={1} md={1} lg={3} className="g-4 " >
      {data.map((data,idx) => (
        <Col key={idx}>
          <Card className="cards" data-aos="zoom-in-up" data-aos-duration="800">
            <Card.Body>

              <Card.Title className="title-history">
             
                {data.title}</Card.Title>
              <Card.Text className="data" >
                {data.desc}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    </Container>
    
    </Box>
  
  );
}

export default History;


