import Modal from "react-bootstrap/Modal";
import DisplayCard  from './displayCard'
import './carousel.css'

const DialogContainer = (props) => {
  const { showDialog, setShowDialog,items } = props;
  return (
    <>
      <Modal
        show={showDialog}
        onHide={() => setShowDialog(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="title-dialog" closeButton>
          <Modal.Title className="text"  id="example-custom-modal-styling-title">
            Area of Business
          </Modal.Title>
        </Modal.Header>
        <Modal.Body  className="dialogContainer" >
        <div className="dialogScroll">
            {items.map((data,idx) => {
          return <DisplayCard title={data.title} details={data.details} img={data.img} className="displayCard" key={idx} />;
        })}
         
         </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DialogContainer;
