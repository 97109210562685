import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import './team.css'
import Card from 'react-bootstrap/Card';
import aaditya from './images/aaditya.jpeg'
import tarun from './images/tarun.jpeg'
import vijay from './images/vijay.jpeg'
import virendar from './images/virendar.JPG'


const team= [
    {
        id:1,
        name:"Mr. Virender Gandhi",
        desc:"Managing Director",
        img:virendar,
        animation:"flip-left",
        delay: 0
    },
    {
        id:2,
        name:"Mr. Vijay Gandhi",
        desc:"Director - Marketing",
        img:vijay,
        animation:"flip-left",
        delay: 200
    },
    {
        id:3,
        name:"Mr. Tarun Gandhi",
        desc:"Director- Finance",
        img:tarun,
        animation:"flip-left",
        delay: 400
    },
    {
        id:4,
        name:"Mr. Aaditya Gandhi",
        desc:"CEO",
        img:aaditya,
        animation:"flip-left",
        delay: 600
    },

]

const Team = () => {
  return (
    <div id="team-nav" className='outer-div-team'>
        <Container>
        <h1 className='title-infra'>MEET OUR TEAM</h1>
        <div className='text-div-infra'>
            <span>
            The most important component of any workflow is the team that builds it. At Sushobhit, we boost excellent workforce of highly trained employees who are adept at handling the state-of-the-art machinery. All the employees are first trained in-house before handing the complete responsibility. 
             We ensure safety and hygiene of each employee from plant to management.
            </span>
        </div>

        <div className='team-cards' >
{team.map((team,idx)=>(
    <Card className="team-card" key={idx} data-aos={team.animation} data-aos-duration="1000" data-easing="ease-in-out" data-aos-delay={team.delay}>
    <Card.Img variant="top" src={team.img} />
    <Card.Body className="cardbody-team">
      <Card.Title>{team.name}</Card.Title>
      <span>{team.desc}</span>
    </Card.Body>
  </Card>
))}
     
</div>
        </Container>
    </div>
  )
}

export default Team;
