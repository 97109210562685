import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './video.css'
import {BsPlayCircleFill} from 'react-icons/bs';
import video1 from './Video/video.mp4';

function Video() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <div onClick={handleShow} className="video-btn">
    <BsPlayCircleFill  />
    &nbsp; <span>Play Now</span>
    </div>
      
 
    
      <Modal fullscreen show={show} onHide={handleClose}>
        <Modal.Header className="modalHeader" closeButton ></Modal.Header >     
        <Modal.Body className="modalBody">
          <div className="outer-modal">
        <video className="tag-video" controls autoPlay>
            <source src={video1} type="video/mp4"></source>
          </video>
          </div>
          </Modal.Body> 
          
      </Modal>
    </>
  );
}

export default Video;