import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './footer.css'
import {BsLinkedin} from 'react-icons/bs';
import {AiTwotoneMail,AiFillCopyrightCircle} from 'react-icons/ai';
import {IoCallSharp} from 'react-icons/io5'
import {ImLocation} from 'react-icons/im'


function Footer() {
  return (
    <Card id="contact-nav">
      <div className="footer-div">
      <h1 className="header-footer">LET'S TALK !!!</h1>
      <Row xs={1} sm={1} md={1} lg={3} className="g-2">
        
        <Col className='colBlock'>
          <Card className='logo-card'>
          <Card.Title className="title-card">Sushobhit Printers</Card.Title>
            <Card.Body>
              <div className="footer-text">
              <Card.Text className="social-handles">
              <BsLinkedin/> @sushobhit
              <br/>
              <AiTwotoneMail/> sushobhit87@gmail.com 
            <br/><br/>
              <Card.Subtitle>
                <ImLocation/> 236, Industrial Area, Alipur, Barwala,<br/>
                 NH-73 Dis. Panchkula <br/>
                 134118<br/>
               (Haryana) INDIA
                </Card.Subtitle>
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className='colBlock'>
          <Card className='contact-card'>
          <Card.Title >Contact Us</Card.Title>
            <Card.Body>
              
              <Card.Text className="social-handles">
            
              <ul className="phn-num"> 
            
                <li><IoCallSharp/>&nbsp;&nbsp;98969 91218</li>
                <li><IoCallSharp/>&nbsp;&nbsp;94679 47495 </li>
                <li><IoCallSharp/>&nbsp;&nbsp;86076 13121</li>
                <li><IoCallSharp/>&nbsp;&nbsp;70567 88011</li>
                
              </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className='colBlock'>
          <Card >
            <Card.Title>Reach us at</Card.Title>
          <Card.Body className='map-card'>
           <iframe id="gmap_canvas"  title="googleFrame"
            src="https://maps.google.com/maps?q=sushobhit%20Printers%20&t=&z=19&ie=UTF8&iwloc=&output=embed" 
            frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </Card.Body>
          </Card>
        </Col>
    </Row>
    <div className='reserved'><AiFillCopyrightCircle/> Copyright. All Rights Reserved to Sushobhit Printers</div>
    </div>
    
      </Card>

  );
}

export default Footer;