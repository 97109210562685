import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navbar.css'
import {Link} from 'react-scroll' 
import SP from './images/SP.png'

const Topbar = () => {
  return (
    <div className='nav-div'>
        <Navbar className='topbar' expand="lg">
      <Container fluid>
        <Navbar.Brand href="#" className="brand">
          <img src={SP} alt="Sushobhit printers" className='logo-img'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className="icon-nav"/>
        <Navbar.Collapse id="navbarScroll" className='nav-bar-collapse' >
          <Nav
            className="me-auto my-2 my-lg-4"
            style={{ maxHeight: '100px' }}
            navbarScroll
          > </Nav>
          <Nav className='navlinks'>
            <Link activeClass='active'  to="home-jumbotrone" spy={true} smooth={true} offset={-30} duration={100} className="nav-link">Home</Link>
            <Link activeClass='active'  to="About-section" spy={true} smooth={true} offset={-60} duration={100} className="nav-link">About</Link>
            {/* <Link  activeClass='active' to="industry-middlebar" spy={true} smooth={true} offset={-60}  duration={100} className="nav-link">Industry</Link> */}
            <Link activeClass='active'  to="team-nav" spy={true} smooth={true} offset={-40}  duration={100} className="nav-link">Team</Link>
            <Link activeClass='active'  to="infra-nav" spy={true} smooth={true} offset={-60}  duration={100} className="nav-link">Infrastructure</Link>
            <Link activeClass='active'  to="clients-nav" spy={true} smooth={true} offset={-60}  duration={100} className="nav-link">Clients</Link>
            <Link activeClass='active'  to="contact-nav" spy={true} smooth={true} offset={-60}  duration={100} className="nav-link">Contact Us</Link>
            {/* 
           \
          
            <Nav.Link href="#action2" className='contents'>Clients</Nav.Link>
            <Nav.Link href="#action2" className='contents'>Contact us</Nav.Link> */} 
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}


export default Topbar