import React from "react";
import Card from '@mui/material/Paper'
import Marquee from 'react-fast-marquee'
import { Container } from "@mui/material";
import './clients.css'
import wow from './images/Wow.png'
import theon from './images/Theon.png'
import mamaearth from './images/Mamaearth.png'
import zee from './images/Zee.png'
import home from './images/Home.png'
import mankind from './images/Mankind.png'
import mother from './images/MotherSparsh.png'
import Universal from './images/Universal.png'

const clients = [
    {
        id:1,
        name:"WOW",
        source:wow
    },
    {
        id:2,
        name:"theon",
        source:theon
    },
    {
        id:4,
        name:"mamaearth",
        source:mamaearth
    },
    {
        id:5,
        name:"zee-labs",
        source:zee
    },
    {
        id:6,
        name:"@home",
        source:home
    },
    {
        id:7,
        name:"mankind",
        source:mankind
    },
    {
        id:8,
        name:"Universal",
        source:Universal
    },
    {
        id:9,
        name:"mother sparsh",
        source:mother 
    }

]


const Clients = () => {
  return (
    <div id="clients-nav" className="outer-div">
        <Container>
       <div className="text-marquee">
        <h1>OUR PRESTEGIOUS CLIENTS</h1>
        <br/>
        <span>
        Our clients vouch for our high-quality and service, 
        and our consumer list speaks for itself. 
        These are some of the reputed corporates
        and advertising businesses who trust
         with Sushobhit for their print needs.
        </span>
       </div>

      
       <div className="marquee-div">
           <Marquee 
           direction="left"
            speed={100} 
            delay={5} 
            shadow="none"
            gradient={false}
            >
             <Card className="card-marquee-inner-div">    
            {clients.map((client,idx) =>(
                        <img className="card-marquee-img" src={client.source} alt={client.name} key={idx}/>                   
            ))}
              </Card>  
           </Marquee>
       </div>
       </Container>
    </div>
  )
}

export default Clients

