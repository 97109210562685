import './carousel.css'
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import Container from 'react-bootstrap/Container'
import "react-multi-carousel/lib/styles.css";
import DisplayCard from "./displayCard";
import DialogContainer from "./dialogContainer";
import pharmaceutical from "./images/pharmaceutical.png"
import wine from './images/wine.png'
import personalcare from './images/personalcare.png'
import electonics from './images/electronics.png'
import automobile from './images/automobile.png'
import sweets from './images/sweets.png'
import hospitality from './images/hospitality.png'
import realEstate from './images/realEstate.png'
import fashion from './images/fashion.png'
import institute from './images/institute.png'
import tabacco from './images/tabacco.png'
import food from './images/food.png'
export const items=[ 
    {
      title: "pharamaceutical",
      img: pharmaceutical,
      details: [
        "Cartons",
        "Labels",
        "Information inserts",
        "Brouchers",
        "Marketing visual aids"
      ]
    },
    {
      title: "Spirits & Bevrages",
      img: wine,
      details: ["Folding Cartons", "Labels", "Speciality Gifts","Festive Packs"]
    },
    {
      title: "Personal Care",
      img: personalcare,
      details: ["Brochures", "Packaging"]
    },
    {
      title: "Electronics",
      img: electonics,
      details: ["Catalogs", "Labels", "Packaging"]
    },
    {
      title: "Automobiles",
      img:automobile,
      details: ["Catalogs", "Seasonal Greetings", "Gifting", "Brouchers"]
    },
    {
        title: "Institutions",
        img:institute,
        details: ["Brochures", "Prospectuses", "Placements Books", "Certificates"]
      },
    
    {
      title: "Hospitality",
      img: hospitality,
      details: ["Property Brochures", "In-house magazines", "High-end Menus"]
    },
    {
      title: "Real Estate",
      img:realEstate,
      details: ["Catalogs", "Project Brochures"]
    },
    {
      title: "Fasion & Beauty",
      img:fashion,
      details: ["Catalogs", "Brochures", "Packaging", "Gifts Packs", "Tags"]
    },
    
    {
      title: "Tabacco",
      img:tabacco,
      details: ["Packaging", "Posters"]
    },
    {
        title: "Sweet Boxes & Confectionery",
        img:sweets,
        details: [
          "Posters",
          "Labels",
          "Packaging",
          "Speciality Gifts/Festive Packs"
        ]
      },
    {
      title: "Food",
      img:food,
      details: ["Cartons", "Rigid Boxes", "Labels", "Gift Packs"]
    }
  ];
export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const [show, setShow] = useState(false);
    const {
      carouselState: { currentSlide, totalItems, slidesToShow }
    } = rest;
   
    const handleOpenDialog = () => {
      setShow(true);
    };
    return (
      <>
        <div className="carousel-button-group">
          <button
            className={`${currentSlide === 0 ? "disable" : ""} prevButton`}           
            onClick={() => previous()}
          >
            Previous
          </button>
          {currentSlide + slidesToShow === totalItems ? (
            <button className="viewAllButton"
            onClick={() => handleOpenDialog()}>View All</button>
          ) : (
            <button className="nextButton" onClick={() => next()}>Next</button>
          )}
        </div>
        <DialogContainer showDialog={show} setShowDialog={setShow} items={items}/>
      </>
    );
  };





const CardCarousel = ()=> {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 4,
      slidesToShow: 4
    },
    desktop: {
      breakpoint: { max: 1800, min: 1000 },
      items: 3,
      slidesToShow: 3
    },
    tablet: {
      breakpoint: { max: 1000, min: 768 },
      items: 2,
      slidesToShow: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToShow: 1
    }
  };

  return (
<div  className="outer-div-carousal">
   <Container>
    <h1 className='title-infra'>AREA OF BUSINESS</h1>
        <div className='text-div-infra'>
            <span>
            We keep the things simple… but with real & unique passion. Delivering our clients the highest level of service, the broadcast selection of products and the most competitive prices.
            </span>
        </div>
        </Container>
    <Container >
      <Carousel
      responsive={responsive}
        arrows={false}
        containerClass="carousel-container"
        renderButtonGroupOutside
        customButtonGroup={<ButtonGroup />}
      >
        {items.slice(0,8).map((data,idx) => {
          return <DisplayCard title={data.title} details={data.details} img={data.img} className="displayCard" key={idx} />;
        })}
      </Carousel>
    </Container>
    </div>
   
  );
}

export default CardCarousel;