import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './middlebar.css'
import CountUp from 'react-countup';
import { Container } from 'react-bootstrap';

const data=[
  {
    id:1,
    number:200,
    suffix:"+",
    text:"Happy Clients"
  },
  {
    id:2,
    number:150,
    suffix:"+",
    text:"Workers Hand"
  },
  {
    id:1,
    number:100,
    suffix:"+",
    text:"Active Experts"
  }
]

const Middlebar = () => {   
  return (
    <div id="industry-middlebar">
    <div className='outer-box1'>
       <q className='middlebar-text'>We're thriving and building better products.</q> 
    </div>

    <Container className="container-middlebar">
    <Row xs={1} md={3} className="g-5">
      {data.map((middle,idx) => (
        <Col key={idx}>
          <Card className="card-middlebar"> 
            <Card.Body>
              <Card.Title className="font-text">
                <div className="counter-text">
                <CountUp 
              end={middle.number}
                suffix={middle.suffix}
                enableScrollSpy={true}
                ></CountUp>
                </div>
                <div>{middle.text}</div>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    </Container>
    
    </div>
  )
}

export default Middlebar