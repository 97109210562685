import Card from "react-bootstrap/Card";
import './carousel.css'

const DisplayCard = (props) => {
  const { title, details,img } = props;
  return (
    <Card style={{ minWidth:"280px",width: '85%', minHeight:'200px', margin:"10px 5px" }}>
        <Card.Header className="header-diplaycard-carousel" style={{textTransform:"uppercase"}}>{title}</Card.Header>
      <Card.Body className="cardbody-card-carousel" >
        <Card.Text style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap-reverse"}}>
            <div> 
                <ul style={{listStyleType:"none", paddingLeft:"0"}}>
            {details.map((data, idx) => {
              return <li className="list-displayCard-carousel" key={idx}>{data}</li>;
            })}
          </ul>
          </div>
          <div>
                <img src={img} alt="img" />
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DisplayCard;