import React from 'react'
import Figure from 'react-bootstrap/Figure';
import Container from 'react-bootstrap/Container';
import './about.css'
import Card from 'react-bootstrap/Card'

const About = () => {
  return (
    <div id="About-section" >
<Container className="outer" >
 <Figure  className="about" >
    <div className="img-stack" data-aos="fade-up" data-aos-duration="1200">
    <Figure.Image
    
      className="back-img"
      width={290}
      height={304}
      alt="background img"
      src="https://images.unsplash.com/photo-1578922427288-a47338083a57?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=695&q=80"
    />
     <Card className="top-img2" >
        <Card.Img
          width={280}
          height={440}
          alt="worker img"
          src="https://images.unsplash.com/photo-1573052775154-df4d35b5b5ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
    />
    </Card>
    </div>
    <Figure.Caption className='data'>
        <Card className="inner" border='light' >
            <Card.Title className="title">WELCOME TO SUSHOBHIT PRINTERS</Card.Title>
            <Card.Text>
            One of the pioneer printing and packaging firm in India, we have attained prominance as
            the leader of the field of printing and packging through, competivness and timely quality
            standards and recorgnized safety performance. 
            <br/><br/>
            In the era of advanced technology and new techniques, 
            we continously strive for innovation  and enhanced efficiency. 
            </Card.Text>
        </Card>
   
    </Figure.Caption>
  </Figure>
  </Container>
    </div>
  )
}

export default About