import Figure from 'react-bootstrap/Figure';
import Container from 'react-bootstrap/Container';
import './wwhyus.css'
import Card from 'react-bootstrap/Card'

const Whyus = () => {
  return (
    <Container className="outer1">
    <Figure  className="about1">
      
       <div className="img-stack1">
       <Figure.Image
         className="back-img1"
         width={350}
         alt="background img"
         src="https://images.unsplash.com/photo-1584210753325-905abf601909?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=814&q=80"
       />
       </div>
       <Figure.Caption className='data1' data-aos="fade-up"  data-aos-duration="800">
           <Card className="inner1" border='light' >
               <Card.Title className="title1">WHY CHOOSE US?</Card.Title>
               <Card.Text>
               Sincere efforts are always made on 
               our behalf, to offer quality products 
               and services to the customers meeting 
               their needs in prominent manner. 
               Our timely deliveries and ethical
              policies have played a vital role
              in winning over the trust of vast 
              clientele.
                <br/><br/>
                We provides a one-stop facility where consumer can get their printing and related requirements fulfilled under one roof.
                 We pride ourselves that our customers think of us as part of their team rather than just a supplier.
               </Card.Text>
           </Card>
      
       </Figure.Caption>
     </Figure>
     </Container>
  )
}

export default Whyus