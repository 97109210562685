import React from 'react'
import Topbar from '../Components/Navbar/Topbar';
import Jumbotrone from '../Components/jumbotrone/Jumbotrone';
import About from '../Components/about/About';
import History from '../Components/history/History';
import Whyus from '../Components/whyus/Whyus';
import Middlebar from '../Components/middlebar/Middlebar';
 import Clients from '../Components/clients/Clients';
import Footer from '../Components/Footer/Footer';
import Infra from '../Components/infra/Infra';
import Team from '../Components/team/Team';
import CardCarousel from '../Components/carousel/cardCarousel';


const Home = () => {
  return (
    <div>
 <Topbar/>
     <Jumbotrone/>
     <About/>
     <History/>
     <Whyus/>
    <Middlebar/>
    <CardCarousel />
    <Team/>
    <Infra/>
    <Clients/>
    <Footer/>
    </div>
  )
}

export default Home
