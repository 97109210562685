import React from 'react'
import './jumbrotone.css'
import Carousel from 'react-bootstrap/Carousel';
import p1 from './images/p1.jpg';
import p2 from './images/p2.jpg';
import p3 from './images/p3.jpg';




const jumbotrone_data=[
  {
    id:1,
    img:p1,
    text:"Printing and Packging",
    animation:"fade-up"
  },
  {
    id:1,
    img:p2,
    text:"Customized Experience",
    animation:"fade-in"
  },
  {
    id:1,
    img:p3,
    text:"In The Time Deliveries",
    animation:"fade-up"
  },
]

const Jumbotrone = () => {

  return (

    <Carousel  id="home-jumbotrone" fade slide={true}  varient="dark"  wrap={true}>
      {jumbotrone_data.map((data,idx)=>(
          <Carousel.Item key={idx}>
        <img
           className="d-block w-100 "
           id="img1"
            src= {data.img}
            alt="First slide"
            

        />
            <Carousel.Caption  >
           <h2 className="carousal-text-jumbotrone">{data.text}</h2>
            </Carousel.Caption>
            
       </Carousel.Item>
      ))}
  </Carousel>
  )
}

export default Jumbotrone